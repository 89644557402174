<template>
	<header class="header">
		<Menubar
			class="header__bar"
			:model="menuItems">
			<template #start>
				<NuxtLink
					class="link header__icon"
					to="/"
					:title="$t('global.main')">
					<img
						class="header__logo"
						src="@/assets/images/logo.png"
						:alt="$t('components.layouts.default.header.logo-alt')" />
				</NuxtLink>
			</template>

			<template #item="{ item, props, root, hasSubmenu }">
				<div v-if="isAuthorized">
					<router-link
						v-if="item.route && item.access"
						v-slot="{ href, navigate }"
						:to="item.route"
						custom>
						<a
							v-ripple
							:href="href"
							v-bind="props.action"
							@click="navigate">
							<NuxtIcon
								v-if="item.custom_icon"
								name="global/list" />

							<span
								v-else
								:class="[item.icon, 'p-menuitem-icon']" />

							<span class="ml-2">{{ item.label }}</span>

							<i
								v-if="hasSubmenu"
								:class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
						</a>
					</router-link>

					<a
						v-else-if="item.access"
						v-ripple
						:href="item.url"
						:target="item.target"
						v-bind="props.action">
						<span :class="[item.icon, 'p-menuitem-icon']" />

						<span class="ml-2">{{ item.label }}</span>

						<i
							v-if="hasSubmenu"
							:class="['pi pi-angle-down', { 'pi-angle-down ml-2': root, 'pi-angle-right ml-auto': !root }]"></i>
					</a>
				</div>
			</template>

			<template #end>
				<div class="header__end">
					<ChangeLang />

					<div
						v-if="isAuthorized"
						class="header__buttons">
						<NuxtLink to="/profile">
							<Button
								class="p-button-outline"
								:label="$t('global.profile-title')"
								icon="pi pi-user" />
						</NuxtLink>

						<Button
							class="p-button-outline"
							icon="pi pi-sign-out"
							@click="logOut" />
					</div>

					<NuxtLink
						v-else
						:to="`${KEY_CLOAK_AUTH_URL}${KEY_CLOAK_REDIRECT_URI}`"
						external>
						<Button
							class="p-button-outline"
							:label="$t('global.log-in')"
							icon="pi pi-sign-in" />
					</NuxtLink>
				</div>
			</template>
		</Menubar>
	</header>
</template>

<script setup lang="ts">
	const componentProps = defineProps({
		userAccess: {
			type: Object,
			required: false,
			default: () => ({
				admin: false,
				search: false,
				order: false,
				product: false,
				price: false,
			}),
		},
	});
	const { t } = useI18n();
	const { status, signOut } = useAuth();

	const isAuthorized = computed(() => status.value === "authenticated");

	const { KEY_CLOAK_AUTH_URL, KEY_CLOAK_LOGOUT_URL, KEY_CLOAK_REDIRECT_URI } = useRuntimeConfig().public;

	const menuItems = computed(() => [
		{
			label: t("global.search-analogues-title"),
			icon: "pi pi-search",
			route: "/search",
			access: componentProps.userAccess.search,
		},
		{
			label: t("global.create-order"),
			icon: "pi pi-plus-circle",
			route: "/orders/add",
			access: componentProps.userAccess.order && componentProps.userAccess["new.order"],
		},
		{
			label: t("global.order-list"),
			icon: "pi pi-list",
			route: "/orders",
			access: componentProps.userAccess.order,
		},
		{
			label: t("global.administration"),
			icon: "pi pi-cog",
			access: componentProps.userAccess.admin,
			items: [
				{
					label: t("global.users"),
					icon: null,
					route: "/users",
					access: componentProps.userAccess.admin,
				},
				{
					label: t("global.dealers"),
					icon: null,
					route: "/dealers",
					access: componentProps.userAccess.admin,
				},
			],
		},
		{
			label: t("global.price-lists"),
			icon: "list",
			custom_icon: true,
			route: "/prices",
			access: componentProps.userAccess.price,
		},
	]);

	const logOut = async () => {
		await signOut({
			redirect: false,
		});

		window.location.href = `${KEY_CLOAK_LOGOUT_URL}${KEY_CLOAK_REDIRECT_URI}`;
	};
</script>

<style lang="scss">
	@import "header";
</style>
